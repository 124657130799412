<template>
  <div>
    <div class="ajjjj" id="wrap">
      <div class="header">
        <!-- logo -->
        <div class="logo fbox fbox-acenter">
          <div class="imgBox">
            <img :src="$store.state.projectInfoServe?.platformLogoPath" />
          </div>
          <span class="font-pmzd">{{
            $store.state.projectInfoServe?.platformName
          }}</span>
        </div>
        <!-- nav -->
        <div class="nav fbox fbox-acenter">
          <div class="navList">
            <div
              :class="{
                li: true,
                active: activeName == item.name || getActive(item),
              }"
              v-for="(item, index) in navList"
              :key="index"
              @click="activeName = item.name"
            >
              <span class="text">{{ item.title }}</span>
              <div class="children" v-if="item.children">
                <div
                  v-for="iitem in item.children"
                  @click.stop="currentChangeTable(iitem)"
                >
                  {{ iitem.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- login -->
        <div class="login">
          <div class="btn" @click="loginGo(item)" v-for="item in loginGoList">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!-- 站位 -->
    <div style="width: 100%; height: 80px"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 展示登录列表
      showLogin: false,
      activeName: "",
      bgColor: "rgba(0,0,0,0.5);",
      loginGoList: ["用户登录", "评标系统", "专家登录", "产权登录"],
      navList: [
        {
          name: "home",
          title: "首页",
        },
        {
          name: "zbinfo",
          title: "招标信息",
        },
        {
          name: "news",
          title: "新闻公告",
        },
        {
          name: "four",
          title: "保函办理",
        },
        {
          name: "five",
          title: "CA办理",
        },
        {
          name: "Tool",
          title: "常用工具",
        },
        {
          name: "assetTransaction",
          title: "资产交易",
          children: [
            {
              name: "transaction",
              title: "产权交易",
            },
            {
              name: "bidding-hall",
              title: "拍卖大厅",
            },
            {
              name: "notification",
              title: "项目公告",
            },
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {
    //跳转其他url
    loginGo(title) {
      if (title == "用户登录") {
        location.href = this.$store.state.userUrl;
      } else if (title == "评标系统") {
        location.href = this.$store.state.expertUrl;
      } else if (title == "定标系统") {
        location.href = this.$store.state.picketageExpertUrl;
      } else if (title == "专家登录") {
        location.href = this.$store.state.expertLoginUrl;
      } else if (title == "产权登录") {
        location.href = this.$store.state.projectInfoServe.cqjyLoginPath;
      }
    },
    //二级跳转
    currentChangeTable(item) {
      this.$router.push("/" + item.name);
    },
    //判断显示
    getActive(item) {
      let dark = false;
      if (item.children && this.activeName) {
        item.children.forEach((item) => {
          if (item.name == this.activeName) {
            dark = true;
          }
        });
      }
      return dark;
    },
  },
  watch: {
    "$route.name"(newVal) {
      this.activeName = newVal;
    },
    activeName(newVal) {
      switch (newVal) {
        case "home":
          this.$router.push("/home");
          break;
        case "news":
          this.$router.push("/news");
          break;
        case "zbinfo":
          this.$router.push("/zbinfo");
          break;
        case "five":
          window.open(this.$store.state.ggzUrl, "__blank");
          setTimeout(() => {
            this.activeName = "home";
          }, 1000);
          break;
        case "Tool":
          this.$router.push("/Tool");
          break;
        case "four":
          window.open(this.$store.state.websiteUrl, "__blank");
          setTimeout(() => {
            this.activeName = "home";
          }, 1000);
          break;
        case "assetTransaction":
          this.$router.push("/transaction");
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  .ajjjj {
    display: none;
  }
}
.ajjjj {
  width: 100%;
  height: 80px;
  // padding: 0 10vw;
  display: flex;
  align-items: center;
  color: white;
  z-index: 999;
  position: fixed;
  background-color: #fff;
  // overflow: hidden;
  // background-image: url('@/assets/images/header-bgc.png');
  // background-size: 100% 100%;
  // background-position: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      color: #333;
      font-size: 26px;
      font-weight: 550;
      display: flex;
      align-items: center;
      .imgBox {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }
    }
    .nav {
      color: #666666;
      height: 100%;
      margin: 0 2vw;
      .navList {
        display: flex;
        align-items: center;
        height: 100%;
        .li {
          position: relative;
          height: 100%;
          line-height: 80px;
          padding: 0 1vw;
          cursor: pointer;

          &:hover {
            background-color: rgba($color: #3475ff, $alpha: 0.1);
            .text {
              color: var(--global-color);
            }
            .children {
              display: block;
            }
          }
          &.active {
            background-color: rgba($color: #3475ff, $alpha: 0.1);
            .text {
              color: var(--global-color);
            }
          }
          .children {
            display: none;
            min-width: 100%;
            position: absolute;
            left: 50%;
            top: 80px;
            transform: translateX(-50%);
            background: #fff;
            z-index: 999;
            text-align: center;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            border-radius: 0 0 6px 6px;
            div {
              &:hover {
                color: var(--global-color);
              }
            }
          }
        }
      }
    }
    .login {
      display: flex;
      align-items: center;
      .btn {
        background-color: var(--global-color);
        margin-left: 15px;
        border-radius: 7px;
        padding: 6px 20px;
        cursor: pointer;
        position: relative;
        text-align: center;
        .login-list {
          // position: absolute;
          // top: 100%;
          // left: 0;
          width: 100%;
          background-color: #fff;
          color: var(--global-color);
          border: 1px solid var(--global-color);
          border-radius: 8px;
          // border-top: none;
          div {
            line-height: 30px;
            border-bottom: 1px dashed var(--global-color);
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
</style>
